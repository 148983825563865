//@import "bootstrap/scss/bootstrap";
@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/variables-dark";
@import "bootstrap/scss/maps";
@import "bootstrap/scss/mixins";
@import "bootstrap/scss/utilities";
@import "bootstrap/scss/root";
@import "bootstrap/scss/reboot";
@import "bootstrap/scss/type";
@import "bootstrap/scss/images";
@import "bootstrap/scss/containers";
@import "bootstrap/scss/grid";
//@import "bootstrap/scss/tables";
@import "bootstrap/scss/forms";
@import "bootstrap/scss/buttons";
@import "bootstrap/scss/transitions";
@import "bootstrap/scss/dropdown";
@import "bootstrap/scss/button-group";
@import "bootstrap/scss/nav";
@import "bootstrap/scss/navbar";
@import "bootstrap/scss/card";
//@import "bootstrap/scss/accordian";
//@import "bootstrap/scss/breadcrumb";
//@import "bootstrap/scss/pagination";
@import "bootstrap/scss/badge";
@import "bootstrap/scss/alert";
@import "bootstrap/scss/progress";
@import "bootstrap/scss/list-group";
@import "bootstrap/scss/close";
@import "bootstrap/scss/toasts";
$modal-backdrop-opacity: 0.3;
$modal-backdrop-bg: transparent;
@import "bootstrap/scss/modal";
//@import "bootstrap/scss/tooltip";
//@import "bootstrap/scss/popover";
//@import "bootstrap/scss/carousel";
@import "bootstrap/scss/spinners";
@import "bootstrap/scss/offcanvas";
@import "bootstrap/scss/placeholders";

// Helpers
@import "bootstrap/scss/helpers";

// Utilities
@import "bootstrap/scss/utilities/api";
// scss-docs-end import-stack

$influxhqGreen: #74a131 !default;
$influxclubBlue: #26a9e0 !default;
$influxappRed: #d62027 !default;
$influxYellow: #fabe2c !default;

body {
  background-color: black;
  background-image: url("https://assets.influxhq.com/tv/backdrops/treadplate.jpg");
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: fixed;
  background-size: cover;
}

//Wild hack? Menu is content high only if not set
.inner-wrap {
  height: 100vh;
}
#topnav {
  transition: 0.5s linear all;
  opacity: 1;
  &.ng-hide {
    opacity: 0;
  }
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.navbar-brand {
  margin-left: 1rem;
}

.tv-view {
  padding-top: 2.5vh;
  height: 100vh;
}

.display-wrapper {
  font-size: 2rem;
  max-width: 95vw;
  max-height: 95vw;
  min-height: 75vh;
  margin-left: auto;
  margin-right: auto;
  background-color: rgba(255, 255, 255, 0.2);
  @include border-radius(4px);
  padding: 0 10px;
  line-height: 0.9em;
  h1 {
    font-size: 2.5rem;
    text-align: center;
  }
  h2 {
    font-size: 1.6em;
  }
  ul {
    list-style-type: none;
  }
  p {
    line-height: 1.1em;
  }
  pre {
    font-size: 0.6em;
    line-height: 1em;
  }
  //http://designpieces.com/2014/02/chalkboard-look-and-feel/
  //Pink Chalk
  //Hex: #f2a3bd
  //Yellow Chalk
  //Hex: #d6d963
  //Turquoise Chalk
  //Hex: #6fe7db
  //Purple Chalk
  //Hex: #c4adc9
  //White Chalk
  //Hex: #f4f4f0
  //Outdoor Chalk colour palette
  //Red Chalk
  //Hex: #ed7777
  //Orange Chalk
  //Hex: #fad48b
  //Yellow Chalk
  //Hex: #f5f9ad
  //Green Chalk
  //Hex: #bcdf8a
  //Blue Chalk
  //Hex: #94c0cc
  //White Chalk
  //Hex: #f6f4f1
}

.left-off-canvas-menu {
  z-index: 1000;
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 350px;
  //padding: 20px;
  transform: translateX(-350px);
  transition: transform 0.3s;
  background-color: #333;
  div.facility {
    border-bottom: 1px solid #ccc;
    align-items: center;
    display: flex;
    justify-content: space-between;
    color: white;
    padding: 0.5rem 1rem;
    font-size: 1.25rem;
    span {
      display: inline-block;
      padding-top: 0.3125rem;
      padding-bottom: 0.3125rem;
    }
  }
  &.is-opened {
    transform: translateX(0px);
  }

  ul {
    margin: 20px;
  }
  ul li.list-group-item {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    .fa {
      line-height: inherit;
    }
  }

  ul li.list-group-title {
    background-color: #eee;
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    span {
      margin: 0;
    }
  }
}

//Source: http://css-tricks.com/dont-overthink-it-grids/ ------------------------------------------------------------------------------------------------------------
$whiteboard-pad: 10px;
.whiteboard-grid {
  display: flex;
  justify-content: space-around;
  margin: 0 0 $whiteboard-pad 0;

  &:after {
    /* Or @extend clearfix */
    content: "";
    display: table;
    clear: both;
  }

  [class*="box-"] {
    padding-right: $whiteboard-pad;
    &:last-of-type {
      padding-right: 0;
    }
  }
  .box-1-1 {
    width: 50%;
    margin: 0 auto;
  }
  .box-1-2 {
    width: 50%;
  }
  .box-1-3 {
    width: 33.333%;
  }
  .box-1-4 {
    width: 25%;
  }
  .box-1-5 {
    width: 20%;
  }
  .box-1-6 {
    width: 16.666%;
  }
  .box-1-7 {
    width: 14.285%;
  }
  .box-1-8 {
    width: 12.5%;
  }
}

.display-message {
  color: #fff;
  font-size: 2vw;
  padding: 1vw 2vw;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  &.urgent {
    background-color: #ba0c2f;
  }
  &.default {
    background-color: #333;
  }
  &.positive {
    background-color: #82b548;
  }
}

.attendees-page {
  // https://medium.com/@fionnachan/dynamic-number-of-rows-and-columns-with-css-grid-layout-and-css-variables-cb8e8381b6f2
  --rowNum: 2;
  --colNum: 8;
  --gridWidth: 200px;
  --gridHeight: 260px;
  .faceboard {
    display: grid;
    grid-template-rows: repeat(var(--rowNum), var(--gridHeight));
    grid-template-columns: repeat(var(--colNum), auto);
  }
  .grid-item {
    grid-column-start: auto;
    grid-row-start: auto;
    margin: 5px;
    height: var(--gridHeight);
    width: var(--gridWidth);
    img {
      height: var(--gridWidth);
      width: var(--gridWidth);
      border-radius: 50%;
    }
    &.state-booked {
      img {
        border: 3px solid $influxclubBlue;
      }
      .overlay {
        background-color: $influxclubBlue;
      }
    }
    &.state-attended {
      img {
        border: 3px solid $influxhqGreen;
      }
      .overlay {
        background-color: $influxhqGreen;
      }
    }
    &.state-latecancelled {
      img {
        border: 3px solid red;
      }
      .overlay {
        background-color: red;
      }
    }
    &.state-noshowed {
      img {
        border: 3px solid red;
      }
      .overlay {
        background-color: red;
      }
    }
    position: relative;

    // https://www.w3schools.com/howto/howto_css_image_overlay_icon.asp
    /* The overlay effect (full height and width) - lays on top of the container and over the image */
    .overlay {
      position: absolute;
      bottom: calc(var(--gridHeight) - var(--gridWidth));
      right: 0;
      height: calc(var(--gridWidth) * 0.4);
      width: calc(var(--gridWidth) * 0.4);
      border-radius: 50%;
      .fa-check:hover {
        color: #eee;
      }
      /* The icon inside the overlay is positioned in the middle vertically and horizontally */
      .icon {
        color: white;
        font-size: 40px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        text-align: center;
      }
    }
    .member-name {
      text-align: center;
      font-size: 0.75em;
    }
  }
}
// If the TV is only 720p, make the text smaller
@media screen and (max-width: 1280px) and (orientation: landscape) {
  body {
    font-size: 80%;
  }
  .display-wrapper {
    font-size: 1.65em;
    h1 {
      margin-bottom: 0;
    }
  }
  attendees-page {
    .grid-item {
      .member-name {
        font-size: 1.2em;
        line-height: 1.2em;
      }
    }
  }
}
